import {
  AddBoldCircleIcon,
  AmericanExpressSvg,
  ApplePaySvg,
  ArrowDownBold2Icon,
  ArrowDownBoldIcon,
  ArrowLeftIcon,
  ArrowRight3Icon,
  ArrowRightBoldIcon,
  ArrowRightIcon,
  ArrowUpBoldIcon,
  BoxBoldIcon,
  BoxTickBoldIcon,
  BoxTimeBoldIcon,
  BuildingBoldIcon,
  CalendarBoldIcon,
  CalendarFavoriteBoldIcon,
  CalendarIcon,
  CalendarTickBoldIcon,
  CardBoldIcon,
  CardRemoveBoldIcon,
  CardTickBoldIcon,
  ChevronRight,
  CloseCircleIcon,
  CopyBoldIcon,
  DangerBoldIcon,
  DeleteBoldIcon,
  DiscoverSvg,
  DocumentTextBoldIcon,
  EditBoldIcon,
  Eye,
  EyeBoldIcon,
  EyeSlash,
  FacebookLight,
  GoogleLight,
  InfoCircle,
  InfoCircleBoldIcon,
  JcbSvg,
  Loader,
  LocationIcon,
  LockCircleBoldIcon,
  MaestroSvg,
  MasterCardSvg,
  Note2BoldIcon,
  NotificationStatusBoldIcon,
  OtherSvg,
  PartyIcon,
  PaypalSvg,
  PPColorIcon,
  SearchIcon,
  Setting4Icon,
  SmsBoldIcon,
  TickCircle,
  TimerBoldIcon,
  UnionPaySvg,
  User2Icon,
  VisaSvg,
} from "./SvgIcon";

const Icon = {
  Location: LocationIcon,
  InfoCircle: InfoCircle,
  ArrowRight: ArrowRightIcon,
  ChevronRight: ChevronRight,
  Loader: Loader,
  Eye: Eye,
  EyeSlash: EyeSlash,
  GoogleLight: GoogleLight,
  FacebookLight: FacebookLight,
  TickCircle: TickCircle,
  SmsBoldIcon: SmsBoldIcon,
  InfoCircleBoldIcon: InfoCircleBoldIcon,
  DangerBoldIcon: DangerBoldIcon,
  CloseCircleIcon: CloseCircleIcon,
  ArrowRightBoldIcon: ArrowRightBoldIcon,
  ArrowDownBoldIcon: ArrowDownBoldIcon,
  SearchIcon: SearchIcon,
  AddBoldCircleIcon: AddBoldCircleIcon,
  ArrowUpBoldIcon: ArrowUpBoldIcon,
  User2Icon: User2Icon,
  DeleteBoldIcon: DeleteBoldIcon,
  EditBoldIcon: EditBoldIcon,
  EyeBoldIcon: EyeBoldIcon,
  Setting4Icon: Setting4Icon,
  CardTickBoldIcon: CardTickBoldIcon,
  CardBoldIcon: CardBoldIcon,
  paypal: PaypalSvg,
  "paypal-mini": PPColorIcon,
  "american-express": AmericanExpressSvg,
  // american_express: AmericanExpressSvg,
  applepay: ApplePaySvg,
  discover: DiscoverSvg,
  jcb: JcbSvg,
  maestro: MaestroSvg,
  other: OtherSvg,
  unionpay: UnionPaySvg,
  visa: VisaSvg,
  mastercard: MasterCardSvg,
  ArrowLeftIcon: ArrowLeftIcon,
  BuildingBoldIcon: BuildingBoldIcon,
  DocumentTextBoldIcon: DocumentTextBoldIcon,
  CopyBoldIcon: CopyBoldIcon,
  PartyIcon: PartyIcon,
  ArrowRight3Icon: ArrowRight3Icon,
  BoxTimeBoldIcon: BoxTimeBoldIcon,
  BoxTickBoldIcon: BoxTickBoldIcon,
  Note2BoldIcon: Note2BoldIcon,
  NotificationStatusBoldIcon: NotificationStatusBoldIcon,
  ArrowDownBold2Icon: ArrowDownBold2Icon,
  BoxBoldIcon: BoxBoldIcon,
  CalendarBoldIcon: CalendarBoldIcon,
  CalendarTickBoldIcon: CalendarTickBoldIcon,
  LockCircleBoldIcon: LockCircleBoldIcon,
  CalendarIcon: CalendarIcon,
  CalendarFavoriteBoldIcon: CalendarFavoriteBoldIcon,
  CardRemoveBoldIcon: CardRemoveBoldIcon,
  TimerBoldIcon: TimerBoldIcon,
  LocationIcon: LocationIcon,
} as any;

export default Icon;
