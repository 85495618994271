import Head from "next/head";
import { ReactNode } from "react";

const Meta = ({
  title,
  description,
  image,
  children,
}: {
  title: string;
  description: string;
  image?: string;
  children?: ReactNode;
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      {image && <meta property="og:image" content={image} />}

      {children}
    </Head>
  );
};
export default Meta;
