import { FC } from "react";

interface Props {
  src?: string;
  alt: string;
  className: string;
  onLoad?: (e: any) => void;
  onClick?: () => void;
}

const noneSrc = "https://www.btklsby.go.id/images/placeholder/basic.png";

const Image: FC<Props> = ({ src, alt, className, onLoad, onClick }) => {
  const link = src ? src : noneSrc;
  return (
    <img
      src={link}
      alt={alt}
      className={className}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = noneSrc;
      }}
      onLoad={onLoad}
      onClick={onClick}
    />
  );
};

export default Image;
