import {
  BookIcon,
  CallCallingIcon,
  DirectNotifIcon,
  InfoCircleBoldIcon,
  MsgIcon,
  MsgQuestionBoldIcon,
  SignPostBoldIcon,
  SmsOutlineNotifIcon,
} from "../component/commons/icon/SvgIcon";

export const history = {
  "paragraf-1": `Storage Unit Auction List was started in 2011 to help people stay up-to-date on auction information. We want the auction experience to be easy and fun for everyone. We have many satisfied customers and continue to work hard to improve our product.`,
  "paragraf-2": `We think that the people who go to auctions are the ones who drive the market. That's why we focus on making sure our customers are happy. Our features and support are for people who go to auctions, not just for the auctioneers. We haven't forgotten about the auction facilities or the auctioneers, either. They're a very important part of this industry, so we've developed a system to collect data from them as efficiently as possible and enabling them to post their own auctions. Plus, we're partnering up with auctioneers all over the country to make sure our services meet everyone's needs.`,
} as {
  "paragraf-1": string;
  "paragraf-2": string;
  "paragraf-3": string;
};

export const weDoIt_1 = [
  {
    Icon: CallCallingIcon,
    title: "97250",
    subtitle: "Phone Calls/Month",
    className: "bg-secondary-50 text-secondary-500",
  },
  {
    Icon: DirectNotifIcon,
    title: "2910",
    subtitle: "Piece Of Mail/Month",
    className: "bg-purple-50 text-purple-500",
  },
  {
    Icon: MsgIcon,
    title: "3750",
    subtitle: "Voice Mail/Month",
    className: "bg-success-50 text-success-500",
  },
] as {
  Icon: (e: any) => JSX.Element;
  title: string;
  subtitle: string;
  className: string;
}[];

export const weDoIt_2 = [
  {
    Icon: SmsOutlineNotifIcon,
    title: "21330",
    subtitle: "E-Mail / Month",
    className: "bg-warning-50 text-warning-500",
  },
  {
    Icon: BookIcon,
    title: "18870",
    subtitle: "Public Resource /Month",
    className: "bg-danger-50 text-danger-500",
  },
] as {
  Icon: (e: any) => JSX.Element;
  title: string;
  subtitle: string;
  className: string;
}[];

export const improve = {
  "paragraf-1": `It is true that you can find the same information we provide for
  free on your own. However, it can be hard to find all the
  information in one place. We make it easy for you by gathering
  all the information in one place and verifying it. Plus, we are
  constantly expanding our database to cover more auctions in more
  locations.`,
} as {
  "paragraf-1": string;
};

export const customer_satisfaction = {
  title: "Customer Satisfaction",
  "paragraf-1": `We know that your time is valuable. We find the best auctions and the most accurate information about auctions for you so you can focus on making more money.
  `,
} as {
  title: string;
  "paragraf-1": string;
  "paragraf-2": string;
};

export const what_you_got = [
  "Exclusive List",
  "Auction Notifications",
  "Calendar View",
  "Much more",
] as string[];

export const pricing_1 = [
  {
    icon: "/images/illustration/Exclusive.svg",
    title: "Exclusive List",
    subtitle:
      "We have an exclusive list of storage unit auctions that you will not find on any other website.",
  },
  {
    icon: "/images/illustration/02.svg",
    title: "Auction Notifications",
    subtitle:
      "You can get email notifications about new auctions that are happening around you.",
  },
  {
    icon: "/images/illustration/Phone.svg",
    title: "Mobile Friendly",
    subtitle:
      "If you're always on the go, take the Storage Unit Auction List with you. You can access it from any device.",
  },
] as {
  icon: string;
  title: string;
  subtitle: string;
}[];

export const pricing_2 = [
  {
    icon: "/images/illustration/Export.svg",
    title: "Export Auction List",
    subtitle:
      "A printable database of live storage auctions with a calendar view to help you stay organized.",
  },
  {
    icon: "/images/illustration/11.svg",
    title: "80,000+ Storage Facilities",
    subtitle:
      "You can find storage auctions in more than 80,000 different locations around the United States.",
  },
  {
    icon: "/images/illustration/Map.svg",
    title: "Across All 50 States",
    subtitle:
      "Access thousands of storage unit auctions happening online, and in person across all 50 states.",
  },
] as {
  icon: string;
  title: string;
  subtitle: string;
}[];

export const contactList = [
  {
    Icon: InfoCircleBoldIcon,
    title: "About Us",
    subtitle: "Do you have any questions about Storage Unit Auction List?",
    className: "bg-primary-100 text-primary-500",
    link: "/about",
  },
  {
    Icon: MsgQuestionBoldIcon,
    title: "Frequently Asked Questions",
    subtitle: "Get an answer for the most frequently asked questions",
    className: "bg-warning-100 text-warning-500",
    link: "/faq",
  },
  {
    Icon: SignPostBoldIcon,
    title: "Need Help Signing Up?",
    subtitle: "Need help signing up as a buyer or storage facility?",
    className: "bg-success-100 text-success-500",
    link: null,
  },
] as {
  Icon: (e: any) => JSX.Element;
  title: string;
  subtitle: string;
  className: string;
  link: string | null;
}[];

export const titleWeb = "Storage Unit Auction List";
