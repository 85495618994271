import { ReactNode, useEffect, useState } from "react";
import Footer from "./partials/Footer";
import Navbar from "./partials/Navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Head from "next/head";
import {
  FooterGroupProps,
  GeneralInformationProps,
} from "../../app/helpers/types/getTypes";
import { setCookie } from "cookies-next";
import useGeneral from "../lib/useGeneral";

const DefaultLayout = ({
  children,
  nearFoot = true,
  topFooter = "",
  noFooter,
  containerClassName = "w-full min-h-screen bg-white",
  mainClassName = "",
  noNavbar = false,
  footerFacility,
  footerLink = true,
}: {
  children: ReactNode;
  nearFoot?: boolean;
  topFooter?: string;
  noFooter?: boolean;
  containerClassName?: string;
  mainClassName?: string;
  noNavbar?: boolean;
  footerFacility?: boolean;
  footerLink?: boolean;
}) => {
  const [information, setInformation] =
    useState<GeneralInformationProps | null>(null);
  const [dataFooter, setDataFooter] = useState<FooterGroupProps[]>([]);
  const { information: info, footer } = useGeneral();

  useEffect(() => {
    // @ts-ignore
    analytics.ready(function () {
      // @ts-ignore
      setCookie("anonId", analytics.user().anonymousId());

      // @ts-ignore
      analytics.page("viewed");
    });

    const dataInfo = localStorage.getItem("general-information") || "";
    if (dataInfo) {
      const getData = JSON.parse(dataInfo);
      setInformation(getData);
    } else {
      setInformation(info);
    }

    const dataFoot = localStorage.getItem("footer-group") || "";
    if (dataFoot) {
      const getDataF = JSON.parse(dataFoot);
      setDataFooter(getDataF);
    } else {
      setDataFooter(footer);
    }
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="icon"
          href={`${information?.attributes?.icon.data?.attributes.url}`}
        />
        <link
          rel="shortcut icon"
          href={`${information?.attributes?.icon.data?.attributes.url}`}
        />
      </Head>
      <div className={`${containerClassName}`}>
        {/* NAVBAR */}
        {!noNavbar && <Navbar data={information} />}

        {/* ----CONTENT---- */}
        <main className={`overflow-hidden ${mainClassName} pb-10`}>
          {children}
        </main>

        {/* --------------- */}
        {/* FOOTER */}
        {!noFooter && (
          <div className="bg-[#030E4D]">
            <Footer
              nearFoot={nearFoot}
              topFooter={topFooter}
              data={information}
              footer={dataFooter}
              footerFacility={footerFacility}
              footerLink={footerLink}
            />
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default DefaultLayout;
