import {
  DocDownloadOutlineIcon,
  House2Icon,
  MobileOutlineIcon,
  SmsOutlineNotifIcon,
} from "../component/commons/icon/SvgIcon";

export const mainMenu = [
  {
    id: "pricing-plan",
    link: "/pricing-plan",
    menu: "Pricing",
    active: false,
  },
  {
    id: "facility",
    link: "/facility",
    menu: "For Storage Facilities",
    active: false,
  },
  {
    id: "about",
    link: "/about",
    menu: "About",
    active: false,
  },
  {
    id: "contact",
    link: "/contact",
    menu: "Contact",
    active: false,
  },
  // {
  //   id: "blog",
  //   link: "/blog",
  //   menu: "Blog",
  //   active: false,
  // },
] as {
  id: string;
  link: string;
  menu: string;
  active: boolean;
}[];

export const advgList = [
  {
    Icon: House2Icon,
    title: "Exclusive List",
    subtitle:
      "We have an exclusive list of storage unit auctions that you will not find on any other website.",
    className: "bg-secondary-50 text-secondary-500",
  },
  {
    Icon: SmsOutlineNotifIcon,
    title: "Auction Notifications",
    subtitle:
      "You can get email notifications about new auctions that are happening around you.",
    className: "bg-success-50 text-success-500",
  },
  {
    Icon: MobileOutlineIcon,
    title: "Mobile Friendly",
    subtitle:
      "If you're always on the go, take the Storage Unit Auction List with you. You can access it from any device.",
    className: "bg-purple-50 text-purple-500",
  },
  {
    Icon: DocDownloadOutlineIcon,
    title: "Export Auction List",
    subtitle:
      "A printable database of live storage auctions with a calendar view to help you stay organized.",
    className: "bg-warning-50 text-warning-500",
  },
] as {
  Icon: (e: any) => JSX.Element;
  title: string;
  subtitle: string;
  className: string;
}[];

export const storageFacility = [
  {
    link: "/images/produk/uhaul.svg",
    alt: "Uhaul",
    className: "md:w-full md:h-auto w-[6.5rem] ",
  },
  {
    link: "/images/produk/public-storage2.svg",
    alt: "Public Storage",
    className: "md:w-full md:h-auto w-[4.063rem] ",
  },
  {
    link: "/images/produk/extraspace2.svg",
    alt: "Extra Space",
    className: "md:w-full md:h-auto w-[5rem]",
  },
  {
    link: "/images/produk/cubesmart2.svg",
    alt: "Cubesmart",
    className: "md:w-full md:h-auto w-[6.438rem]",
  },
  {
    link: "/images/produk/lifestorage2.svg",
    alt: "LifeStorage",
    className: "md:w-full md:h-auto w-[6.5rem]",
  },
  {
    link: "/images/produk/simplyself2.svg",
    alt: "SimplySelf",
    className: "md:w-full md:h-auto w-[6.5rem]",
  },
] as {
  link: string;
  alt: string;
  className: string;
}[];

export const reviewsList = [
  {
    src: "/images/avatar/profile-yellow.svg",
    name: "Mary Mories",
    desc: "Storage Unit Auction List saves me hours each week sorting through auctions.",
  },
  {
    src: "/images/avatar/profile-gray.svg",
    name: "Kyle Sulkar",
    desc: "Storage Unit Auction List saves me hours each week sorting through auctions. Storage Unit Auction List saves me hours each week sorting through auctions.",
  },
  {
    src: "/images/avatar/profile-blue.svg",
    name: "Samuel Jones",
    desc: "Storage Unit Auction List saves me hours each week sorting through auctions. Storage Unit Auction List saves me hours each week sorting through auctions.",
  },
  {
    src: "/images/avatar/profile-purple.svg",
    name: "Johana",
    desc: "Storage Unit Auction List saves me hours each week sorting through auctions.",
  },
  {
    src: "/images/avatar/profile-red.svg",
    name: "Jordan Welch",
    desc: "Storage Unit Auction List saves me hours each week sorting through auctions.",
  },
  {
    src: "/images/avatar/profile-yellow.svg",
    name: "George Klein",
    desc: "Storage Unit Auction List saves me hours each week sorting through auctions. Storage Unit Auction List saves me hours each week sorting through auctions.",
  },
  {
    src: "/images/avatar/profile-green.svg",
    name: "Samuel Jones",
    desc: "Storage Unit Auction List saves me hours each week sorting through auctions. Storage Unit Auction List saves me hours each week sorting through auctions.",
  },

  {
    src: "/images/avatar/profile-red.svg",
    name: "Dylan Pearson",
    desc: "Storage Unit Auction List saves me hours each week sorting through auctions.",
  },
] as {
  src: string;
  name: string;
  desc: string;
}[];

export const menuFooter = {
  company: [
    {
      link: "/about",
      menu: "About Us",
    },
    {
      link: "/blog",
      menu: "Blog",
    },
    {
      link: "/contact",
      menu: "Contact",
    },
    {
      link: "/faq",
      menu: "FAQ",
    },
  ],
  product: [
    {
      link: "#",
      menu: "Buyer Portal",
    },
    {
      link: "#",
      menu: "Storage Facility Portal",
    },
    {
      link: "/facility",
      menu: "Storage Facility",
    },
    {
      link: "/pricing-plan",
      menu: "Pricing",
    },
  ],
  explores: [
    {
      link: "/facility/list",
      menu: "Storage In States",
    },
    {
      link: "/facility/list",
      menu: "Storage In City",
    },
  ],
} as {
  company: {
    link: string;
    menu: string;
  }[];
  product: {
    link: string;
    menu: string;
  }[];
  explores: {
    link: string;
    menu: string;
  }[];
};

export const advgListFacility = [
  {
    icon: "/images/illustration/01.svg",
    title: "Reach more",
    subtitle: "storage unit buyers",
    desc: "More than 300,000 buyers have used Storage Unit Auction List to search for upcoming auctions.",
  },
  {
    icon: "/images/illustration/02.svg",
    title: "Send notifications about",
    subtitle: "upcoming auctions",
    desc: "Storage Unit Auction announcements will be sent to nearby buyers.",
  },
  {
    icon: "/images/illustration/11.svg",
    title: "Strengthen your storage",
    subtitle: "facilities online presence",
    desc: "Stand out in your community by promoting your storage unit auctions on Storage Unit Auction List",
  },
  {
    icon: "/images/illustration/04.svg",
    title: "Deliver a better storage unit",
    subtitle: "auction experience.",
    desc: "Offer your community a better way to find auctions happening at your storage facilities.",
  },
] as {
  icon: string;
  title: string;
  subtitle: string;
  desc: string;
}[];
