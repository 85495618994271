import { Popover } from "@headlessui/react";
import Link from "next/link";
import { ReactNode, useEffect, useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { usePopper } from "react-popper";
import { usePopperTooltip } from "react-popper-tooltip";
import { LocationBoldIcon, House2BoldIcon } from "../icon/SvgIcon";
import Image from "../image/Image";
import { PopCustomDiv } from "./Pop";

export const PopoverOne = ({
  visible = false,
  animate = "animate__fadeIn",
}: {
  visible?: boolean;
  animate?: string;
}) => {
  return (
    <>
      <AnimationOnScroll
        animateIn={animate}
        animateOnce
        className="flex flex-col space-y-2"
        initiallyVisible={visible}
      >
        <div className="font-bold text-neutral-900 md:text-title3 text-regular-tight">
          80,000+ Storage Facilities
        </div>
        <div className="md:text-small-normal text-neutral-600 text-tiny-normal">
          You can find storage auctions in more than 80,000 different locations
          around the United States.
        </div>
        <div className="flex flex-row items-center space-x-3.5">
          <div className="w-fit">
            <Image
              src="/images/img-1.png"
              alt="Step 1"
              className="md:w-full h-auto w-12"
            />
          </div>
          <div className="w-fit">
            <Image
              src="/images/img-2.png"
              alt="Step 2"
              className="md:w-full h-auto w-12"
            />
          </div>
          <div className="w-fit">
            <Image
              src="/images/img-3.png"
              alt="Step 3"
              className="md:w-full h-auto w-12"
            />
          </div>
          <div className="w-fit">
            <Image
              src="/images/img-4.png"
              alt="Step 4"
              className="md:w-full h-auto w-12"
            />
          </div>
        </div>
      </AnimationOnScroll>
    </>
  );
};

export const PopoverTwo = ({
  visible = false,
  animate = "animate__fadeIn",
}: {
  visible?: boolean;
  animate?: string;
}) => {
  return (
    <>
      <AnimationOnScroll
        animateIn={animate}
        animateOnce
        className="flex flex-col space-y-2"
        initiallyVisible={visible}
      >
        <div className="font-bold text-neutral-900 md:text-title3 text-small-normal">
          Across All 50 States
        </div>
        <div className="md:text-small-normal text-neutral-600 text-tiny-normal">
          Access thousands of storage unit auctions happening online, and in
          person across all 50 states.
        </div>
      </AnimationOnScroll>
    </>
  );
};

export const PopoverApp = ({
  url,
  descBuyer,
  descFac,
  title,
}: {
  url: string;
  descBuyer: string;
  descFac: string;
  title: string;
}) => {
  return (
    <div className="flex flex-col space-y-4.5">
      <div className="text-regular-tight text-neutral-900 font-medium">
        {title}
      </div>
      <Link href={`${process.env.NEXT_PUBLIC_BUYER_URL}/b/${url}`}>
        <a
          className="flex flex-row items-start space-x-2.5 p-2 rounded-2lg hover:bg-neutral-100 hover-anime"
          target="_blank"
        >
          <div className="rounded-full p-2 text-white bg-primary-500">
            <LocationBoldIcon />
          </div>
          <div className="flex flex-col space-y-2">
            <div className="text-small-none font-medium text-neutral-900">
              Storage Buyer
            </div>
            <div className="text-tiny-normal text-neutral-600">{descBuyer}</div>
          </div>
        </a>
      </Link>
      <Link href={`${process.env.NEXT_PUBLIC_BUYER_URL}/f/${url}`}>
        <a
          className="flex flex-row items-start space-x-2.5 p-2 rounded-2lg hover:bg-neutral-100 hover-anime"
          target="_blank"
        >
          <div className="rounded-full p-2 text-white bg-purple-500">
            <House2BoldIcon />
          </div>
          <div className="flex flex-col space-y-2">
            <div className="text-small-none font-medium text-neutral-900">
              Facility Manager
            </div>
            <div className="text-tiny-normal text-neutral-600">{descFac}</div>
          </div>
        </a>
      </Link>
    </div>
  );
};

const PopOverCustom = ({
  children,
  buttonElement,
  panelClassName,
  containerClassName,
  offset = 20,
}: {
  children: ReactNode;
  buttonElement: ReactNode;
  panelClassName?: string;
  containerClassName?: string;
  offset?: number;
}) => {
  const [close, setClose] = useState(true);
  const [referenceElement, setReferenceElement] = useState<any>();
  const [popperElement, setPopperElement] = useState<any>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "auto",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, offset],
        },
      },
      {
        name: "flip", //flips popper with allowed placements
        options: {
          fallbackPlacements: ["bottom-end", "bottom-start"],
          allowedAutoPlacements: ["top", "bottom"],
          rootBoundary: "viewport",
        },
      },
    ],
  });

  useEffect(() => {
    if (popperElement) {
      setClose(false);
    } else {
      setClose(true);
    }
  }, [popperElement]);

  return (
    <PopCustomDiv
      containerClassName={`w-auto ${containerClassName}`}
      btnRef={setReferenceElement}
      btnClassName=""
      button={<>{buttonElement}</>}
    >
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className={`bg-white rounded-xl shadow-lv4 w-auto p-3 focus-visible:!outline-none ${panelClassName}`}
      >
        {({ close }) => <div>{children}</div>}
      </Popover.Panel>
    </PopCustomDiv>
  );
};

export const TooltipAuto = ({
  element,
  children,
  className,
  fallbackPlacements = ["bottom-end", "bottom-start"],
  allowedAutoPlacements = ["top", "bottom"],
  closeOnOutsideClick = true,
  offset = 20,
}: {
  element: ReactNode;
  children: ReactNode;
  className?: string;
  fallbackPlacements?: string[];
  allowedAutoPlacements?: string[];
  closeOnOutsideClick?: boolean;
  offset?: number;
}) => {
  const [tooltipShow, setTooltipShow] = useState(false);
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip(
      {
        visible: tooltipShow,
        onVisibleChange: setTooltipShow,
        closeOnOutsideClick: closeOnOutsideClick,
        trigger: "click",
      },
      {
        placement: "auto",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, offset],
            },
          },
          {
            name: "flip", //flips popper with allowed placements
            options: {
              fallbackPlacements: fallbackPlacements,
              allowedAutoPlacements: allowedAutoPlacements,
              rootBoundary: "viewport",
            },
          },
        ],
      }
    );

  return (
    <div ref={setTriggerRef} className="">
      <div className="cursor-pointer">{element}</div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default PopOverCustom;
