import Link from "next/link";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Button from "../../component/commons/button/Button";
import { TwitterIcon, FbIcon } from "../../component/commons/icon/SvgIcon";
import Image from "../../component/commons/image/Image";
import { titleWeb } from "../../helpers/ContentList";
import {
  FooterGroupProps,
  GeneralInformationProps,
} from "../../helpers/types/getTypes";

const Footer = ({
  nearFoot = true,
  topFooter = "",
  data,
  footer = [],
  footerFacility,
  footerLink = true,
}: {
  nearFoot?: boolean;
  topFooter?: string;
  data: GeneralInformationProps | null;
  footer: FooterGroupProps[];
  footerFacility?: boolean;
  footerLink?: boolean;
}) => {
  return (
    <>
      {/* NEAR FOOT */}
      {nearFoot ? (
        <div className="container mx-auto">
          <div
            className={`mt-12 lg:mt-20 xl:mt-[11.875rem] pt-7 lg:pt-20 lg:pb-[5.75rem] px-[1.375rem] lg:px-10 xl:px-[10.938rem] relative bg-pattern bg-center bg-no-repeat ${topFooter}`}
          >
            <div className="flex flex-col">
              <div
                className={`w-[18.188rem] ${
                  footerFacility ? "md:w-[26rem]" : "md:w-[33.438rem]"
                } font-bold text-white md:text-5xl md:leading-[4.5rem] text-title3 leading-9`}
              >
                {footerFacility ? (
                  <>
                    Ready to add{" "}
                    <span className="text-warning-500">your facility?</span>
                  </>
                ) : (
                  <>
                    Ready to hunt for{" "}
                    <span className="text-warning-500">Storage Auctions?</span>
                  </>
                )}
              </div>
              <div className="mt-3 md:mt-4.5 md:text-title3 md:leading-9 text-white text-regular-normal font-light">
                {footerFacility
                  ? "Promote your storage unit auctions"
                  : "Find nearby storage unit auctions"}
              </div>
              <div className="mt-3 md:mt-7 ">
                <Link
                  href={`${process.env.NEXT_PUBLIC_BUYER_URL}/${
                    footerFacility ? "f" : "b"
                  }/sign-up`}
                >
                  <a target="_blank">
                    <Button
                      size="lg"
                      className="!bg-secondary-500 !rounded-full"
                    >
                      {footerFacility
                        ? "Add Your Facility"
                        : "Search For Auctions"}
                    </Button>
                  </a>
                </Link>
              </div>
            </div>
            <AnimationOnScroll
              animateIn="animate__slideInUp"
              className="lg:absolute lg:bottom-0 lg:right-[1rem] xl:right-[6.563rem] mt-2.5 lg:mt-0"
              animateOnce
              animatePreScroll={false}
            >
              <AnimationOnScroll
                animateIn="animate__fadeIn"
                animateOnce
                delay={500}
                animatePreScroll={false}
              >
                <Image
                  src="/images/phone-footer.svg"
                  alt="Phone"
                  className="w-[36rem] xl:w-[37.819rem]"
                />
              </AnimationOnScroll>
            </AnimationOnScroll>
          </div>
        </div>
      ) : (
        <div className={`mt-12 ${topFooter}`}></div>
      )}

      {/* FOOTER */}
      {footerLink && (
        <footer className="bg-[#030E4D] border-t border-[#1C2662]">
          <div className="container mx-auto">
            <div className="px-4.5 lg:px-10 xl:px-[10.938rem] pt-11 flex lg:flex-row lg:justify-between space-y-[1.375rem] lg:space-y-0 lg:space-x-5 flex-col">
              <div className="flex flex-col space-y-3.5">
                <div className="flex flex-row space-x-2 items-center">
                  <div className="min-w-[8rem] sm:min-w-[[8.938rem]">
                    <Image
                      src={`${data?.attributes?.logo_light.data?.attributes.url}`}
                      alt=""
                      className="w-[8rem] sm:w-[8.938rem]"
                    />
                  </div>
                  <div className="text-white font-bold text-regular-normal md:text-title3 hidden">
                    {titleWeb}
                  </div>
                </div>
                <div className="text-primary-300 md:text-regular-normal md:leading-[1.625rem] md:w-[26.5rem] text-small-normal">
                  {data?.attributes?.footer_header_title}
                  <br />
                  {data?.attributes?.footer_description}
                </div>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-5">
                {footer.map((e, key: number) => (
                  <div
                    key={key}
                    className={`flex flex-col space-y-4.5 md:w-[12.625rem] w-[9.938rem]`}
                  >
                    <div className="font-bold text-white text-large-none md:text-title3 uppercase">
                      {e.attributes.name}
                    </div>
                    <>
                      {e.attributes.footer_links.data.map((v, id: number) => (
                        <Link href={v.attributes.url ?? "#"} key={id}>
                          <a className="text-primary-300 md:text-regular-normal text-tiny-normal">
                            {v.attributes.name}
                          </a>
                        </Link>
                      ))}
                    </>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-[1.375rem] lg:mt-[3.625rem] lg:px-10 xl:px-[10.938rem]">
              <div className="border-t border-[#1C2662] flex flex-col lg:flex-row items-center lg:justify-between lg:space-x-2 lg:py-7 py-3.5 lg:space-y-0 space-y-6">
                <div className="flex flex-col lg:flex-row items-center lg:space-x-12 space-y-3 lg:space-y-0 text-primary-300 lg:text-small-tight text-tiny-none">
                  <Link href="/">{`© 2022, ${titleWeb}`}</Link>
                  <div className="flex flex-row lg:space-x-12 space-x-3">
                    <Link href="/terms-and-conditions">Terms of Service</Link>
                    <Link href="/privacy-policy">Privacy Policy</Link>
                  </div>
                </div>
                <div className="flex flex-row items-center space-x-6 text-primary-300 text-small-tight">
                  <div>Follow Us On</div>
                  <div className="flex flex-row items-center space-x-3.5">
                    <Link href="https://twitter.com/stgauctionlist/">
                      <a target="_blank">
                        <TwitterIcon />
                      </a>
                    </Link>

                    <Link href="https://www.facebook.com/StorageUnitAuctionList">
                      <a target="_blank">
                        <FbIcon />
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
