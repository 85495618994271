import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Button from "../../component/commons/button/Button";
import {
  CloseCircleOutlineIcon,
  MiniMenuIcon,
} from "../../component/commons/icon/SvgIcon";
import Image from "../../component/commons/image/Image";
import {
  PopoverApp,
  TooltipAuto,
} from "../../component/commons/popover/Popover";
import { titleWeb } from "../../helpers/ContentList";
import { mainMenu } from "../../helpers/MenuList";
import { GeneralInformationProps } from "../../helpers/types/getTypes";

const Navbar = ({ data }: { data: GeneralInformationProps | null }) => {
  const router = useRouter();
  const [open, isOpen] = useState(false);
  const [active, setActive] = useState("");

  const getMenuActive = () => {
    setActive(router.asPath);
  };

  useEffect(() => {
    getMenuActive();
  }, [router]);

  return (
    <>
      <nav className="sticky top-0 z-30 bg-white ">
        <div className="flex flex-wrap justify-between items-center container mx-auto py-3.5 px-4.5 lg:py-[1.063rem] lg:px-16 ">
          <Link href="/">
            <a className="flex flex-row items-center space-x-2 w-11/12 lg:w-auto">
              <div className="min-w-[8rem] sm:min-w-[8.938rem]">
                {/* <LogoColor size="34" /> */}
                <Image
                  src={`${data?.attributes?.logo.data?.attributes.url}`}
                  alt=""
                  className="w-[8rem] sm:w-[8.938rem]"
                />
              </div>
              <div className="font-bold text-neutral-900 text-large-tight hidden">
                {titleWeb}
              </div>
            </a>
          </Link>
          <div className="hidden lg:block">
            <ul className="flex flex-wrap lg:justify-start lg:items-start justify-center items-center text-regular-tight text-neutral-600 py-3">
              {mainMenu.map((e, key: number) => (
                <li className="px-8" key={key}>
                  <Link href={e.link}>
                    <a
                      className={`cursor-pointer hover:text-primary-500 hover-anime ${
                        active === e.link ? "text-primary-500 font-bold" : ""
                      }`}
                    >
                      {e.menu}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="lg:flex flex-row items-center space-x-3.5 hidden">
            <TooltipAuto
              element={
                <Button className="!rounded-full !bg-transparent !text-primary-500 !border-primary-500">
                  Sign In
                </Button>
              }
            >
              <div className="bg-white rounded-xl shadow-lv4 w-auto p-3 focus-visible:!outline-none md:!w-[15.875rem]">
                <PopoverApp
                  url="sign-in"
                  title="Sign In as"
                  descBuyer="Find auctions nearby"
                  descFac="Manage your facility and auctions"
                />
              </div>
            </TooltipAuto>
            <TooltipAuto
              element={<Button className="!rounded-full">Sign Up</Button>}
            >
              <div className="bg-white rounded-xl shadow-lv4 w-auto p-3 focus-visible:!outline-none md:!w-[15.875rem]">
                <PopoverApp
                  url="sign-up"
                  title="Sign Up as"
                  descBuyer="Register as a storage buyer to find auctions"
                  descFac="Sign up as Facility Manager and register your facility"
                />
              </div>
            </TooltipAuto>
          </div>
          <div
            className="lg:hidden cursor-pointer w-1/12 flex justify-center"
            onClick={() => isOpen(!open)}
          >
            {!open ? <MiniMenuIcon /> : <CloseCircleOutlineIcon />}
          </div>
        </div>

        <div
          className={`${
            !open && "hidden"
          } w-full bg-white shadow-lg absolute left-0 lg:hidden`}
        >
          <ul className="flex flex-col text-[14px] font-normal p-4 px-4.5">
            {mainMenu.map((e, key: number) => (
              <li key={key}>
                <Link href={e.link}>
                  <a
                    className={`block p-3 text-small-tight text-neutral-600 ${
                      active === e.link ? "text-primary-500 font-bold" : ""
                    }`}
                    onClick={() => {}}
                  >
                    {e.menu}
                  </a>
                </Link>
              </li>
            ))}
          </ul>
          <div className="mt-2 flex flex-col md:flex-row w-full md:space-y-0 md:space-x-3.5 space-y-3.5 p-4.5">
            <TooltipAuto
              element={
                <Button className="!rounded-full !bg-transparent !text-primary-500 !border-primary-500 w-full">
                  Sign In
                </Button>
              }
              offset={10}
              className="w-full z-10"
            >
              <div className="bg-white rounded-xl shadow-lv4 p-3 focus-visible:!outline-none w-full ">
                <PopoverApp
                  url="sign-in"
                  title="Sign In as"
                  descBuyer="Find auctions nearby"
                  descFac="Manage your facility and auctions"
                />
              </div>
            </TooltipAuto>
            <TooltipAuto
              element={
                <Button className="!rounded-full w-full">Sign Up</Button>
              }
              offset={10}
              className="w-full "
            >
              <div className="bg-white rounded-xl shadow-lv4 p-3 focus-visible:!outline-none w-full ">
                <PopoverApp
                  url="sign-up"
                  title="Sign Up as"
                  descBuyer="Register as a storage buyer to find auctions"
                  descFac="Sign up as Facility Manager and register your facility"
                />
              </div>
            </TooltipAuto>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
