import { FC, ReactNode, forwardRef } from "react";
import Icon from "../icon/Icon";

interface Props {
  type?: "button" | "submit";
  color?:
    | "outline-success"
    | "neutral"
    | "primary"
    | "secondary"
    | "danger"
    | "warning"
    | "info"
    | "success"
    | "purple";
  size?: "sm" | "md" | "lg";
  children?: ReactNode;
  className?: string;
  icon?: string;
  iconPosition?: "left" | "right";
  isIconShort?: boolean;
  isLoading?: boolean;
  isOutline?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  form?: string;
  iconSize?: string;
  props?: any;
}

const sizes = {
  sm: "py-2 px-4",
  md: "py-2.5 px-8",
  lg: "py-4 px-8",
};

const sizesWithIcon = {
  left: {
    long: {
      sm: "py-2 px-4",
      md: "py-2.5 px-8",
      lg: "py-4 px-8",
    },
    short: { sm: "py-2 px-4", md: "py-2.5 px-8", lg: "py-4 px-8" },
  },
  right: {
    long: {
      sm: "py-2 px-4",
      md: "py-2.5 px-8",
      lg: "py-4 px-8",
    },
    short: { sm: "py-2 px-4", md: "py-2.5 px-8", lg: "py-4 px-8" },
  },
} as any;

const sizesAttr = {
  sm: "text-small-tight leading-[1rem] rounded-2lg",
  md: "text-regular-none rounded-2lg",
  lg: "text-regular-none rounded-xl",
};

const colors = {
  neutral: "bg-neutral-300 text-neutral-500 cursor-not-allowed",
  primary: "bg-primary-500 hover:bg-primary-600 text-white",
  secondary:
    "bg-primary-200 hover:bg-primary-300 text-primary-500 hover:text-primary-600",
  danger: "bg-danger-500 hover:bg-danger-600 text-white",
  warning: "bg-warning-500 hover:bg-warning-600 text-white",
  info: "bg-info-500 hover:bg-info-600 text-white",
  success:
    "bg-success-700 hover:bg-success-600 text-white border-2 border-success-700 hover:border-success-600",
  purple: "bg-purple-500 hover:bg-purple-600 text-white",
  outline:
    "bg-white hover:bg-neutral-100 text-neutral-900 border border-neutral-300",
  "outline-success":
    "bg-white hover:bg-neutral-100 text-green-700 hover:text-green-600 border-2 border-green-700 hover:border-green-600 font-semibold",
};

const iconRange = {
  long: { sm: "gap-[8px]", md: "gap-[24px]", lg: "gap-[24px]" },
  short: { sm: "gap-[4px]", md: "gap-[8px]", lg: "gap-[8px]" },
} as any;

// eslint-disable-next-line react/display-name
const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      type = "button",
      color = "primary",
      size = "md",
      children,
      className = "",
      icon = "",
      iconPosition = "left",
      isIconShort = false,
      isLoading = false,
      isOutline,
      disabled = false,
      onClick,
      form,
      iconSize,
      props,
    },
    ref
  ) => {
    let colorClasses = colors[color];
    if (disabled) {
      colorClasses = colors["neutral"];
    } else if (isOutline) {
      colorClasses = colors["outline"];
    }

    let SpesificIcon;
    let sizeClasses = sizes[size];
    let iconType = isIconShort ? "short" : "long";
    if (icon) {
      SpesificIcon = Icon[icon];
      if (children) {
        sizeClasses = sizesWithIcon[iconPosition][iconType][size];
      } else {
        sizeClasses = sizes[size];
      }
    }

    if (isLoading) {
      SpesificIcon = Icon["Loader"];
    }

    let positionClasses = "flex-row";
    if (iconPosition === "right") {
      positionClasses = "flex-row-reverse";
    }

    const sizeAttrClasses = sizesAttr[size];
    const iconRangeClasses = iconRange[iconType][size];

    return (
      <button
        ref={ref}
        form={form}
        type={type}
        className={`flex ${positionClasses} items-center justify-center font-medium ${colorClasses} ${sizeClasses} ${sizeAttrClasses} ${iconRangeClasses} ${className} border border-transparent relative`}
        disabled={disabled || isLoading}
        onClick={onClick}
        {...props}
      >
        {(icon || isLoading) && (
          <SpesificIcon
            size={size === "sm" ? "16" : iconSize ? iconSize : null}
            className={`${
              color === "neutral" || disabled
                ? "fill-neutral-900"
                : color === "secondary"
                ? "fill-primary-500"
                : "fill-white"
            } ${isLoading && "animate-spin"} ${
              !isLoading && "absolute"
            } md:block ${iconPosition === "left" ? "left-3" : "right-3"}`}
          />
        )}
        {children && !isLoading && (
          <span className={`w-auto ${iconPosition === "right" ? "mr-4" : ""}`}>
            {children}
          </span>
        )}
      </button>
    );
  }
);

export default Button;
